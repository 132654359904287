const colors = {
	black: "var(--black)",
	white: "var(--white)",
	grey01: "var(--grey01)",
	grey02: "var(--grey02)",
	grey03: "var(--grey03)",
	grey04: "var(--grey04)",
	grey05: "var(--grey05)",
	charcoal: "var(--charcoal)",
	charcoalLine: "var(--charcoal-line)",
	blue01: "var(--blue01)",
	blue02: "var(--blue02)",
	blue01_24: "var(--blue01-24)",
	red01: "var(--red01)",
	red02: "var(--red02)",
	red01_24: "var(--red01-24)",
	orange01: "var(--orange01)",
	orange02: "var(--orange02)",
	orange01_24: "var(--orange01-24)",
	green01: "var(--green01)",
	green02: "var(--green02)",
	green01_24: "var(--green01-24)",

	// Some things aren't animating correctly in GSAP when using CSS variables. So this is a fallback for those cases.
	gsap: {
		black: "#151515",
		white: "#FFF",
		grey01: "#F2F4F8",
		grey02: "#DDE2EC",
		grey03: "#8E9494",
		grey04: "#464F51",
		grey04_16: "#464F5150",
		grey05: "#252627",
		charcoal: "#252627",
		charcoalLine: "#191919",
		blue01: "#0087FF",
		blue02: "#0045D0",
		blue01_24: "#0087FF24",
		red01: "#FF212B",
		red02: " #F00000",
		red01_24: "#FF212B24",
		orange01: "#FFAC00",
		orange02: "#FF6800",
		orange01_24: "#FFAC0024",
		green01: "#00E146",
		green02: "#00BA4E",
		green01_24: "#00E14624",
	},
} as const

export default colors

import { useRafInterval } from "ahooks"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"

import textStyles from "styles/text"
import useTweenedValue from "./useTweenedValue"

export default function Compass({
	className = "",
	onHeadingChange,
	heading,
}: {
	className?: string
	onHeadingChange?: (heading: number) => void
	heading?: number
}) {
	const [rawHeading, setCurrentHeading] = useState(180)
	const currentHeading = useTweenedValue(heading ?? rawHeading)

	useRafInterval(() => {
		const maxChange = 10
		setCurrentHeading((p) => {
			const newValue = p + Math.random() * maxChange * 2 - maxChange
			return Math.max(0, Math.min(360, newValue))
		})
	}, 1000)

	useEffect(() => {
		onHeadingChange?.(rawHeading)
	}, [onHeadingChange, rawHeading])

	const direction = (() => {
		if (currentHeading < 22.5) return "N"
		if (currentHeading < 67.5) return "NE"
		if (currentHeading < 112.5) return "E"
		if (currentHeading < 157.5) return "SE"
		if (currentHeading < 202.5) return "S"
		if (currentHeading < 247.5) return "SW"
		if (currentHeading < 292.5) return "W"
		if (currentHeading < 337.5) return "NW"
		return "N"
	})()

	const notches = (
		<>
			<Notches offset={currentHeading}>270°</Notches>
			<Notches offset={currentHeading}>315°</Notches>
			<Notches offset={currentHeading}>360°</Notches>
			<Notches offset={currentHeading}>45°</Notches>
			<Notches offset={currentHeading}>90°</Notches>
			<Notches offset={currentHeading}>135°</Notches>
			<Notches offset={currentHeading}>180°</Notches>
			<Notches offset={currentHeading}>225°</Notches>
			<Notches offset={currentHeading}>270°</Notches>
			<Notches offset={currentHeading}>315°</Notches>
			<Notches offset={currentHeading}>360°</Notches>
			<Notches offset={currentHeading}>45°</Notches>
		</>
	)

	return (
		<Wrapper className={className}>
			<LeftNotches>{notches}</LeftNotches>
			<Center>
				<Indicator>{direction}</Indicator>
				<FocusedNumber>{Math.round(currentHeading)}°</FocusedNumber>
			</Center>
			<RightNotches>{notches}</RightNotches>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	${fresponsive(css`
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		gap: 20px;
	`)}

	${fmobile(css`
		scale: 0.75;
		transform-origin: top left;
		top: 12px;
		gap: 10px;
	`)}
`

const LeftNotches = styled.div`
	${fresponsive(css`
		overflow: hidden;
		width: 300px;
		display: flex;

		> *:first-child {
			margin-left: -116px;
		}
	`)}

	${ftablet(css`
		width: 250px;
	`)}

  ${fmobile(css`
		width: 200px;
	`)}
`

const Center = styled.div`
	display: grid;
	place-items: center;
`

const Indicator = styled.div`
	${fresponsive(css`
		padding: 5px;
		width: 40px;
		display: grid;
		place-items: center;
		border-radius: 99vw;
		background: ${colors.charcoal};
		color: white;
		font-family: Slussen, sans-serif;
		font-size: 8px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.4px;
	`)}
`

const FocusedNumber = styled.div`
	${fresponsive(css`
		color: white;
		text-align: center;
		font-family: Fraktion, sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	`)}
`

const RightNotches = styled(LeftNotches)`
	${fresponsive(css`
		> *:first-child {
			margin-left: -504px;
		}
	`)}
`

function Notches({ children, offset }: { children: string; offset: number }) {
	return (
		<NotchesWrapper
			style={{
				transform: `translateX(${(-offset / 360) * 800}%)`,
				transition: "transform 0.1s",
			}}
		>
			<LargeNotch>
				<Notch />
				{children}
			</LargeNotch>
			<Notch />
			<Notch />
			<Notch />
			<Notch />
		</NotchesWrapper>
	)
}

const NotchesWrapper = styled.div`
	will-change: transform;
	${fresponsive(css`
		display: flex;
		gap: 40px;
		padding-left: 40px;
	`)}
`

const Notch = styled.div`
	${fresponsive(css`
		width: 2px;
		height: 8px;
		border-radius: 99vw;
		background: white;
	`)}
`

const LargeNotch = styled.div`
	${fresponsive(css`
		${textStyles.product4};
		width: 2px;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: white;
		text-align: center;

		${Notch} {
			${fresponsive(css`
				margin-bottom: 10px;
				height: 15px;
			`)}
		}
	`)}
`

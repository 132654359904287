import { type Config, defaultConfig } from "library/defaultConfig"

/**
 * config for the reform util library
 * see src/library/defaultConfig.ts for the default config
 */

/**
 * The transition names that can be used in the page transition
 */
export type TransitionNames = "slide"

const config: Config = {
	...defaultConfig,
	scaleFully: false,
	defaultTransition: "slide",
	scrollRestoration: false,
	getTimeNeeded: (startTime: number) => startTime * 4 + 1000,
}

export default config

import { isBrowser } from "library/deviceDetection"

export const mobileBreakpoint = 500
export const mobileDesignSize = 375

export const tabletBreakpoint = 1024
export const tabletDesignSize = 1024

export const desktopBreakpoint = 1600
export const desktopDesignSize = 1440

const media = {
	// standard media queries
	mobile: `@media screen and (max-width: ${mobileBreakpoint}px)`,
	mobileAndLandscape: `@media screen and (max-width: ${mobileBreakpoint}px), screen and (max-width: ${tabletBreakpoint}px) and (orientation: landscape)`,
	mobileLandscape: `@media screen and (max-width: ${tabletBreakpoint}px) and (orientation: landscape)`,
	tablet: `@media screen and (min-width: ${
		mobileBreakpoint + 1
	}px) and (max-width: ${tabletBreakpoint}px)`,
	tabletPortrait: `@media screen and (min-width: ${
		mobileBreakpoint + 1
	}px) and (max-width: ${tabletBreakpoint}px) and (orientation: portrait)`,
	touchTabletToDesktop: `@media screen and (min-width: ${tabletBreakpoint + 1}px) and (max-width: ${desktopBreakpoint}px) and (pointer: coarse)`,
	desktop: `@media screen and (min-width: ${
		tabletBreakpoint + 1
	}px) and (max-width: ${desktopBreakpoint}px)`,
	fullWidth: `@media screen and (min-width: ${desktopBreakpoint + 1}px)`,

	// raw versions for for use in gsap.matchMedia
	gsap: {
		mobile: `(max-width: ${mobileBreakpoint}px)`,
		tablet: `(min-width: ${
			mobileBreakpoint + 1
		}px) and (max-width: ${tabletBreakpoint}px)`,
		desktop: `(min-width: ${
			tabletBreakpoint + 1
		}px) and (max-width: ${desktopBreakpoint}px)`,
		fullWidth: `(min-width: ${desktopBreakpoint + 1}px)`,
	},

	// hover queries
	hover: "@media (hover: hover) ",
	noHover: "@media (hover: none) ",
} as const

export const detectTabletLandScape = () => {
	if (!isBrowser) return false

	// This will also detect touchscreens on a desktop

	const isTouchDevice = "ontouchstart" in window
	const isLandscape = window.innerWidth > window.innerHeight
	const isTouchTabletToDesktop =
		isTouchDevice &&
		isLandscape &&
		window.innerWidth >= tabletBreakpoint + 1 &&
		window.innerWidth <= desktopBreakpoint
	console.info("isTouchTabletToDesktop:", isTouchTabletToDesktop)
	return isTouchTabletToDesktop
}

export default media

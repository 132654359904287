import gsap from "gsap"
import useAnimation from "library/useAnimation"
import { startTransition, useState } from "react"

const useTweenedValue = (targetValue: number) => {
	const [value, setValue] = useState(targetValue)

	// biome-ignore lint/correctness/useExhaustiveDependencies: getting an error either way so removing.
	useAnimation(
		() => {
			const animater = { value }

			gsap.to(animater, {
				value: targetValue,
				duration: 1,
				ease: "linear",
				onUpdate: () => startTransition(() => setValue(animater.value)),
			})
		},
		[targetValue],
		{
			kill: true,
		},
	)

	return value
}

export default useTweenedValue

import * as Form from "@radix-ui/react-form"
import * as ScrollArea from "@radix-ui/react-scroll-area"
import * as Select from "@radix-ui/react-select"
import { ReactComponent as ArrowSVG } from "images/global/arrow.svg"
import ClientOnly from "library/ClientOnly"
import { isBrowser } from "library/deviceDetection"
import { fresponsive } from "library/fullyResponsive"
import { useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import { localeSortedCountryList } from "./countryData"
import { statesList } from "./stateData"

type Option = {
	label: string
	value: string
	displayOrder: number
	doubleData: number
	hidden: boolean
	description: string
	readOnly: boolean
}

const DisplayNames = new Intl.DisplayNames(undefined, {
	type: "region",
})

const getUserCountry = () => {
	if (isBrowser) {
		return window.navigator.language.split("-")[1]?.toUpperCase() ?? "US"
	}
}

const renderOption = (value: string, label: string, key = value) => (
	<Item key={key} value={value}>
		<Select.ItemText>{label}</Select.ItemText>
		<Select.ItemIndicator />
	</Item>
)

const SelectOptions = ({
	options,
	name,
	userCountry,
	sortedStateList,
	localeSortedCountryList,
	DisplayNames,
}: {
	options: Option[]
	name: string
	userCountry: string
	sortedStateList: string[]
	localeSortedCountryList: string[]
	DisplayNames: Intl.DisplayNames
}) => {
	if (options.length !== 0) {
		return options.map((option) => renderOption(option.value, option.label))
	}

	if (name.includes("state")) {
		return sortedStateList.map((state) => renderOption(state, state))
	}

	return (
		<>
			{userCountry &&
				renderOption(userCountry, DisplayNames.of(userCountry) ?? "")}
			{localeSortedCountryList
				.filter((code) => code !== userCountry)
				.map((code) => renderOption(code, DisplayNames.of(code) ?? ""))}
		</>
	)
}

export default function CountriesDropdown({
	options,
	placeholder,
	name,
}: {
	options?: Option[]
	placeholder: string
	name: string
}) {
	const userCountry = getUserCountry()
	const [value, setValue] = useState<string>()

	return (
		<>
			{/* mirror the state to the parent form */}
			<Control value={value} required aria-hidden />
			<Select.Root onValueChange={setValue}>
				{/* button to open/close selectbox */}
				<Trigger>
					<Select.Value placeholder={placeholder} />
					<LightArrow />
				</Trigger>
				<ClientOnly>
					<Select.Portal>
						{/* the content div */}
						<Select.Content
							position="popper"
							asChild
							sideOffset={8}
							style={{ zIndex: 103 }}
						>
							<Content>
								{/* the scroll area is composed of a select viewport & a scroll area viewport */}
								<ScrollRoot type="always">
									<Select.Viewport asChild>
										<Viewport>
											<SelectOptions
												options={options ?? []}
												name={name}
												userCountry={userCountry ?? "US"}
												sortedStateList={statesList}
												localeSortedCountryList={localeSortedCountryList}
												DisplayNames={DisplayNames}
											/>
										</Viewport>
									</Select.Viewport>
									{/* our styled scrollbar */}
									<Scrollbar orientation="vertical">
										<Thumb>
											<Arrow />
											<Arrow />
										</Thumb>
									</Scrollbar>
								</ScrollRoot>
							</Content>
						</Select.Content>
					</Select.Portal>
				</ClientOnly>
			</Select.Root>
		</>
	)
}

const Control = styled(Form.Control)`
	visibility: hidden;
	position: absolute;
	pointer-events: none;

	&[data-invalid] ~ * {
		border-color: ${colors.red01} !important;
		color: ${colors.red01} !important;
	}
`

const Trigger = styled(Select.Trigger)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	transition: 0.2s ease-in-out;
	transition-property: color, border-bottom;
	color: ${colors.white};
	border-bottom: 1px solid ${colors.grey04};
	${textStyles.body2};
	outline: none;

	&[data-placeholder] {
		color: ${colors.grey04};

		&:hover {
			color: ${colors.grey03};
			border-bottom: 1px solid ${colors.grey03};
		}
	}

	&:focus-visible {
		border-bottom: 1px solid ${colors.white};
	}

	${fresponsive(css`
		padding-bottom: 16px;
	`)}
`

const Arrow = styled(ArrowSVG)`
	${fresponsive(css`
		width: 9px;
		transform-origin: top left;
		rotate: -90deg;
		scale: -1 1;
	`)}
`

const LightArrow = styled(Arrow)`
	${fresponsive(css`
		margin-right: 18px;
		margin-left: -9px;
		translate: 9px 4.5px;
	`)}

	* {
		fill: ${colors.grey04};
	}
`

const Content = styled.div`
	${fresponsive(css`
		color: ${colors.white};
		top: 100%;
		width: var(--radix-select-trigger-width);
		border-radius: 12px;
		background: linear-gradient(
			214deg,
			rgb(0 0 0 / 80%) -34.53%,
			rgb(30 30 30 / 51%) 79.89%
		);
		box-shadow: 0 10px 18px 0 rgb(0 0 0 / 10%);
		backdrop-filter: blur(5px);
		height: 260px;
	`)}
`

const ScrollRoot = styled(ScrollArea.Root)`
	${fresponsive(css`
		width: 100%;
		height: 100%;
		--radix-scroll-area-thumb-height: 18px;
		--radix-scroll-area-thumb-width: 16px;
	`)}
`

const Viewport = styled(ScrollArea.Viewport)`
	${textStyles.body3};
	${fresponsive(css`
		padding: 8px 12px 10px 10px;
		width: 100%;
		height: 100%;
	`)}
`

const Item = styled(Select.Item)`
	color: ${colors.grey04};
	transition: color 0.2s ease-in-out;
	outline: none;
	cursor: pointer;

	&:hover,
	&:focus-visible {
		color: ${colors.white};
	}

	${fresponsive(css`
		&:not(:last-child) {
			padding-bottom: 6px;
		}
	`)}
`

const Scrollbar = styled(ScrollArea.Scrollbar)`
	${fresponsive(css`
		display: flex;
		margin: 10px;
		background: ${colors.grey04};
		border-radius: 99vw;
		padding: 10px 2px;
	`)}
`

const Thumb = styled(ScrollArea.Thumb)`
	display: grid;
`

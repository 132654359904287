import NavProvider from "./MenuColor"

import { ScreenProvider } from "library/ScreenContext"

interface ProvidersProps {
	children: React.ReactNode
}

export default function Providers({ children }: ProvidersProps) {
	return (
		<ScreenProvider>
			<NavProvider>{children} </NavProvider>
		</ScreenProvider>
	)
}

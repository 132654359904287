const links = {
	careers: "/careers",
	product: "/product",
	about: "/about",
	home: "/",
	blog: "/blog",
	pressKit: "mailto:press@aerodome.com",
	hardware: "/product/#product-hardware",
	features: "/product/#solution",
	jobOpenings: "#job-openings",
	company: "/company",
	twitter: "https://twitter.com/flyaerodome",
	instagram: "https://www.instagram.com/flyaerodome",
	linkedin: "https://www.linkedin.com/company/aerodomeus/",
}

export default links

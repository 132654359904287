import Header from "components/Header"
import gsap from "gsap"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { useBackButton } from "library/Loader/TransitionUtils"
import Scroll from "library/Scroll"
import { fresponsive } from "library/fullyResponsive"
import { useTrackPageReady } from "library/pageReady"
import { Suspense, lazy } from "react"
import {
	StaticPattern,
	staticBackground,
	staticBackgroundStatic,
} from "sections/home/01-Hero/Static"
import styled, { createGlobalStyle, css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import ContactModal from "./ContactModal"
import PageTransition from "./PageTransition"
import { Preloader } from "./Preloader"
import { useNavConfig } from "./Providers/MenuColor"

gsap.registerPlugin(ScrollSmoother)

const Footer = lazy(() => import("components/Footer"))

interface LayoutProps {
	children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
	useTrackPageReady()
	useBackButton()
	const { paused } = useNavConfig()

	return (
		<>
			<GlobalStyle />
			<PageTransition />
			<Preloader />
			<ContactModal />
			<Header />
			<Scroll>
				<Main $paused={paused}>{children}</Main>
				<Suspense>
					<Footer />
				</Suspense>
			</Scroll>
			<StaticPattern />
		</>
	)
}

const Main = styled.main<{ $paused: boolean }>`
	overflow: hidden;

	${({ $paused }) => ($paused ? staticBackgroundStatic : staticBackground)};

	${fresponsive(css`
		padding-top: 80px;
	`)}

	${fresponsive(css`
		padding-top: 71px;
	`)}
`

const globalCss = css`
	:root {
		--black: #151515;

		--white: #fff;

		--grey01: #f2f4f8;

		--grey02: #dde2ec;

		--grey03: #8e9494;

		--grey04: #464f51;

		--grey05: #252627;

		--charcoal: #252627;

		--charcoal-line: #191919;

		--blue01: #0087ff;

		--blue02: #0045d0;

		--blue01-24: #0087ff24;

		--red01: #ff212b;

		--red02: #f00000;

		--red01-24: #ff212b24;

		--orange01: #ffac00;

		--orange02: #ff6800;

		--orange01-24: #ffac0024;

		--green01: #00e146;

		--green02: #00ba4e;

		--green01-24: #00e14624;
	}

	@supports (color: color(display-p3 1 1 1)) {
		:root {
			/* This is only surpressing when I do this for each line smh */
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--black-p3: color(display-p3 0.0833 0.0833 0.0833);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--white: color(display-p3 1 1 1);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--grey01: color(display-p3 0.949 0.9569 0.9725);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--grey02: color(display-p3 0.8706 0.8863 0.9216);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--grey03: color(display-p3 0.5624 0.5783 0.5793);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--grey04: color(display-p3 0.281 0.3081 0.3167);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--grey05: color(display-p3 0.1451 0.149 0.1529);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--charcoal: color(display-p3 0.1208 0.1208 0.1208);

			--charcoal-line: color(display-p3 0.0961 0.0961 0.0961);

			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--blue01: color(display-p3 0.2042 0.5225 1);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--blue02: color(display-p3 0 0.2663 0.7833);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--blue01-24: color(display-p3 0.2042 0.5225 1 / 24%);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--red01: color(display-p3 0.9608 0.251 0.2275);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--red02: color(display-p3 0.8613 0 0);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--red01-24: color(display-p3 0.9608 0.251 0.2275 / 24%);

			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--orange01: color(display-p3 1 0.69 0.225);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--orange02: color(display-p3 0.9875 0.4529 0.1522);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--orange01-24: color(display-p3 1 0.69 0.225 / 24%);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--green01: color(display-p3 0 0.8667 0.3608);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--green02: color(display-p3 0 0.7311 0.3043);
			/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
			--green01-24: color(display-p3 0 0.8667 0.3608 / 24%);
		}
	}

	html {
		color: ${colors.black};
		${textStyles.body1}
	}

	* {
		/* need this so that fonts match figma */
		text-rendering: geometricprecision;
		-webkit-font-smoothing: antialiased;
	}

	/* fixes for visbug */
	vis-bug {
		position: fixed;
		z-index: var(--layer-1);
	}

	visbug-metatip,
	visbug-handles,
	visbug-ally {
		position: absolute;
		z-index: var(--layer-top);
	}

	/** restore default focus states for elements that need them */
	*:focus-visible {
		outline: 2px solid #f008;
	}
`

const GlobalStyle = createGlobalStyle`${globalCss}`

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { ReactComponent as CloseSVG } from "images/global/arrow.svg"
import { loader } from "library/Loader"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Banner({
	setBannerOpen,
	banners,
}: {
	setBannerOpen: (open: boolean) => void
	banners: Queries.BannerQuery
}) {
	const initialLoad = useRef(true)
	const wrapper = useRef<HTMLDivElement>(null)
	const [showBanner, setShowBanner] = useState(false)

	const bannerToShow = banners?.allContentfulAnnouncementBannerMessage.edges
		.map((e) => e.node)
		.find(
			(b) =>
				// start date either undefined or is in the past
				(b.showMessageAt === null || new Date(b.showMessageAt) < new Date()) &&
				// end date is in the future
				b.hideMessageAt &&
				new Date(b.hideMessageAt) > new Date(),
		)

	loader.useEventListener("end", () => {
		if (initialLoad.current) {
			initialLoad.current = false
			setShowBanner(true)
			setBannerOpen(true)
		}
	})

	const closeOpen = useAnimation(
		() => {
			const bannerHeight =
				(wrapper.current?.getBoundingClientRect().height ?? 0) + 1
			const tl = gsap.timeline({
				paused: true,
				onComplete: () => {
					ScrollTrigger.refresh()
				},
				onReverseComplete: () => {
					ScrollTrigger.refresh()
				},
			})

			tl.fromTo(
				[wrapper.current],
				{
					marginBottom: 0,
					y: 0,
					ease: "power3.inOut",
				},
				{
					marginBottom: -bannerHeight,
					y: -bannerHeight,
					duration: 1,
					ease: "power3.inOut",
				},
				0,
			)
			tl.fromTo(
				"main",
				{
					transform: `translate(0px, ${bannerHeight}px)`,
					duration: 1,
					ease: "power3.inOut",
				},
				{
					transform: "translate(0px, 0px)",
					duration: 1,
					ease: "power3.inOut",
				},

				0,
			)

			return tl
		},
		[],
		{
			recreateOnResize: true,
		},
	)

	useEffect(() => {
		if (showBanner) closeOpen?.reverse()
		else closeOpen?.play()
	}, [showBanner, closeOpen])

	if (!bannerToShow?.bannerMessage?.bannerMessage) return null
	return (
		<UniversalLink to={bannerToShow.linkUrl ?? "/"}>
			<Wrapper onClick={() => setShowBanner(false)} ref={wrapper}>
				<Copy>{bannerToShow.bannerMessage.bannerMessage}</Copy>
				<CloseSVG />
			</Wrapper>
		</UniversalLink>
	)
}

const Wrapper = styled.div`
	background: ${colors.blue01};
	color: ${colors.white};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 101;
	position: relative;

	${fresponsive(css`
		gap: 16px;
		height: 48px;
		padding: 18px 20px;

		svg {
			display: block;
			width: 16px;
			height: 16px;

			path {
				fill: ${colors.white};
			}
		}
	`)}

	${ftablet(css`
		height: 64px;
		padding: 15px 20px;
	`)}

	${fmobile(css`
		height: 56px;
		padding: 18px 16px;
	`)}
`

const Copy = styled.p`
	${textStyles.body3};
	word-wrap: balance;

	${ftablet(css`
		${textStyles.body3};
	`)}

	${fmobile(css`
		${textStyles.body3};
	`)}

  * {
		display: inline;
	}
`

import { useRafInterval } from "ahooks"
import { useNavConfig } from "components/Providers/MenuColor"
import gsap from "gsap"
import DarkStatic from "images/global/darkStatic.webp"
import LightStatic from "images/global/lightStatic.webp"
import lightStaticStill from "images/global/lightStaticStill.png"
import Noise from "images/global/noise.webp"
import { useRef } from "react"
import styled, { css, keyframes } from "styled-components"

const moveBackground = keyframes`
    ${Array.from({ length: 100 }, (_, index) => {
			return css`
				${index}% {
					background-position: ${Math.floor(Math.random() * 2000)}px
						${Math.floor(Math.random() * 2000)}px;
				}
			`
		})}
`

export const staticBackground = css`
	background-image: url(${LightStatic});
	background-size: 650px;
	animation: ${moveBackground} 1s infinite;
	animation-timing-function: steps(1, end);
`

export const staticBackgroundStatic = css`
	background-image: url(${lightStaticStill});
	background-size: 650px;
`

export const darkStaticBackground = css`
	background-image: url(${DarkStatic});
	background-size: 650px;
	animation: ${moveBackground} 1s infinite;
	animation-timing-function: steps(1, end);
`

const Static = styled.div`
	opacity: 0.5;
	background-image: url(${Noise});
	background-size: 650px;
	animation: ${moveBackground} 1s infinite;
	animation-timing-function: steps(1, end);
`

// define as a pattern for use in SVGs
export const StaticPattern = () => {
	const { paused } = useNavConfig()
	const image = useRef<SVGImageElement>(null)

	useRafInterval(() => {
		if (paused) return
		gsap.set(image.current, {
			x: Math.random() * -100,
			y: Math.random() * -100,
		})
	}, 0)

	return (
		<svg style={{ position: "fixed", top: "-999vh" }}>
			<title>Static</title>
			<defs>
				<pattern
					id="static"
					patternUnits="userSpaceOnUse"
					width="100"
					height="100"
				>
					<image href={LightStatic} ref={image} />
				</pattern>
			</defs>
		</svg>
	)
}

export default Static

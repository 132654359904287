import gsap from "gsap"
import { ReactComponent as BoxSVG } from "images/modal/ModalBox.svg"
import { loader } from "library/Loader"
import { usePreloader } from "library/Loader/PreloaderUtils"
import { fmobile, fresponsive } from "library/fullyResponsive"
import { useRef, useState } from "react"
import Compass from "sections/home/01-Hero/Compass"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import Logo from "./Logo"

const duration = 1
const delay = 1

export const Preloader = () => {
	const wrapper = useRef<HTMLDivElement>(null)
	// sync heading between two compasses
	const [heading, setHeading] = useState(0)

	const fadeOut = () => {
		const tl = gsap.timeline({})
		tl.to(wrapper.current, {
			duration,
			y: "120vh",
			scale: 0.7,
			ease: "power4.in",
			delay,
		})

		tl.to("main", {
			clearProps: "all",
		})
	}

	const onProgress = (progress: number) => {
		setHeading(progress * 3.6)
	}

	usePreloader({
		callback: fadeOut,
		duration: duration + delay,
	})
	loader.useEventListener("progressUpdated", onProgress)

	return (
		<Wrapper ref={wrapper}>
			<Background />
			<StaticCompass heading={heading} />
			<LogoWrap aria-hidden>
				<Logo forcePlaying />
			</LogoWrap>
			<StaticCompass heading={heading} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	pointer-events: none;
	z-index: 999;
	inset: 0;
	background: ${colors.black};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	${fresponsive(css`
		padding: 40px 15px;
	`)}
`

const Background = styled(BoxSVG)`
	${fresponsive(css`
		position: absolute;
		left: 0;
		width: 100%;
		top: -50px;
		z-index: -1;
	`)}
`

const StaticCompass = styled(Compass)`
	position: static;
	transform: unset;
	opacity: 0.5;
	transform-origin: unset;
	will-change: transform;
`

const LogoWrap = styled.div`
	${fresponsive(css`
		width: 160px;
		overflow: hidden;
	`)}
	${fmobile(css`
		width: 130px;
	`)}

  * {
		fill: white;
	}
`

import gsap from "gsap"
import useAnimation from "library/useAnimation"
import { useRef } from "react"
import styled from "styled-components"
import colors from "styles/colors"
import { useNavConfig } from "./Providers/MenuColor"

interface LogoProps {
	className?: string
	dark?: boolean
	forcePlaying?: boolean
}

export default function Logo({
	className,
	dark = false,
	forcePlaying,
}: LogoProps) {
	const path1Ref = useRef<SVGPathElement | null>(null)
	const path2Ref = useRef<SVGPathElement | null>(null)
	const hover = useRef(false)
	const { paused } = useNavConfig()

	const timeline = useAnimation(() => {
		const tl = gsap.timeline({
			paused: forcePlaying ? !forcePlaying : true,
			onComplete: () => {
				if (hover.current || forcePlaying) {
					tl.play(0)
				}
			},
		})

		tl.to(
			path2Ref.current,
			{
				yPercent: -200,
				ease: "power2.in",
			},
			0,
		)

		tl.to(
			path1Ref.current,
			{
				yPercent: -450,
				ease: "power2.in",
			},
			0.1,
		)

		tl.set(
			path2Ref.current,
			{
				yPercent: 150,
			},
			1,
		)

		tl.set(
			path1Ref.current,
			{
				yPercent: 400,
			},
			1.1,
		)

		tl.to(
			path2Ref.current,
			{
				yPercent: 0,
				ease: "power2.out",
			},
			1,
		)

		tl.to(
			path1Ref.current,
			{
				yPercent: 0,
				ease: "power2.out",
			},
			1.1,
		)

		return tl
	}, [forcePlaying])

	const mouseEnter = () => {
		hover.current = true
		timeline?.play(0)
	}

	const mouseLeave = () => {
		hover.current = false
	}

	return (
		<div
			onMouseEnter={mouseEnter}
			onMouseLeave={mouseLeave}
			className={className}
		>
			<Svg viewBox="-22 -22 89 90" fill="none">
				<path
					ref={path1Ref}
					d="M4.83154 39.2968C4.67038 39.3175 4.5958 39.1031 4.73503 39.0193L22.1344 28.5511C22.2296 28.4938 22.3487 28.4938 22.4439 28.5511L39.8396 39.0193C39.9788 39.1031 39.9042 39.3175 39.743 39.2968L22.4422 37.073C22.3406 37.06 22.2377 37.06 22.1361 37.073L4.83154 39.2968Z"
					fill={dark ? colors.white : colors.black}
				/>
				<path
					ref={path2Ref}
					d="M21.8909 17.2236L17.9295 26.5377C17.9038 26.5982 17.8589 26.6486 17.8017 26.6811L4.43728 34.2858C4.3066 34.3602 4.1599 34.2176 4.23054 34.0848L18.5671 7.14744C18.6192 7.04949 18.7211 6.98828 18.8321 6.98828H25.7424C25.8534 6.98828 25.9553 7.04949 26.0074 7.14744L40.344 34.0848C40.4146 34.2176 40.2679 34.3602 40.1373 34.2858L26.7728 26.6811C26.7157 26.6486 26.6708 26.5982 26.645 26.5377L22.6836 17.2236H21.8909Z"
					fill={dark ? colors.white : colors.black}
				/>
			</Svg>
		</div>
	)
}

const Svg = styled.svg`
	width: 200%;
	height: 200%;
	margin: -50%;
`
